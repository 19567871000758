import type { FC, PropsWithChildren } from 'react';
import { ColorModeProvider } from './color-mode/provider';
import { LocalizationProvider } from './localization.provider';
import {
  ThemeProvider,
  type Props as ThemeProviderProps,
} from './theme.provider';

type Props = ThemeProviderProps;

export const DesignSystemProvider: FC<PropsWithChildren<Props>> = (props) => {
  const { children, ...rest } = props;

  return (
    <LocalizationProvider>
      <ColorModeProvider>
        <ThemeProvider {...rest}>
          {children}
          {/* <EasterEggProvider /> */}
        </ThemeProvider>
      </ColorModeProvider>
    </LocalizationProvider>
  );
};
