import { ExpandMore } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import type { FC } from 'react';

type Props = {
  title: string;
  more?: string;
  desc?: string;
  index?: string;
  canBeOpen?: boolean;
  isStar?: boolean;
};

export const ListItemAccordion: FC<Props> = (props: Props) => {
  const {
    title,
    more,
    desc = '',
    index = 0,
    canBeOpen = false,
    isStar = false,
  } = props;

  return (
    <Accordion
      disabled={!canBeOpen}
      sx={{
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '0 !important',
        boxShadow: 'none',
        '&.Mui-disabled': {
          backgroundColor: 'inherit',
        },
      }}
    >
      <AccordionSummary
        expandIcon={canBeOpen ? <ExpandMore /> : null}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'white',
          transition: 'borderColor ease-in-out 0.5s',
          '&.Mui-disabled': {
            opacity: '1',
          },
          '&.Mui-expanded': {
            minHeight: '48px',
            borderColor: '#e5e7eb',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
          },
        }}
      >
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography fontWeight="bolder">
              {index} | {title}
            </Typography>
            {isStar && <StarIcon fontSize="small" sx={{ color: 'gold' }} />}
          </Stack>
          {more && <Typography fontWeight="bolder">{more}</Typography>}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{desc}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
