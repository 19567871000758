import { Chip } from '@mui/material';
import type { FC } from 'react';

type Props = {
  keyWord: string;
};

export const KeyWordChip: FC<Props> = (props: Props) => {
  const { keyWord } = props;

  return (
    <Chip
      label={keyWord}
      color={'primary'}
      variant="outlined"
      sx={{ borderRadius: '2px', fontWeight: 'bolder' }}
    />
  );
};
